import './scss/App.scss';
import Header from './components/Header';
import Footer from './components/Footer';
import Search from './components/Search';
import { useState } from 'react';

function App() {
  const [ loader, setLoader ] = useState(false);
  return (
    
    <div className="app">
      <h1>Lockeed</h1>
      <p>On arrive bientôt, patience !</p>
    </div>
  );
}

export default App;